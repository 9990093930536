// HR integration icons
import bamboo from "../../../images/integration/bamboo.png"
import workday from "../../../images/integration/workday.png"
import adp from "../../../images/integration/adp.png"
import peopleHr from "../../../images/integration/people_hr.svg"
import paylocity from "../../../images/integration/paylocity.png"
import breatheHr from "../../../images/integration/breathe_hr.png"
import kronosIcon from "../../../images/integration/kronos.svg"
import ukgIcon from "../../../images/integration/ukg.png"
import sageIcon from "../../../images/integration/sage.png"
import ripplingIcon from "../../../images/integration/rippling.png"
import HiBobIcon from "../../../images/integration/hibob.png"
export interface Integration_Row {
  title?: string
  name: string
  icon: string
  isSupported: boolean
  maxWidth?: any
  className?: string
  connected?: boolean
  howToLink?: string
  enabled?: boolean
}

export enum HRSystem {
  PeopleHR = "PeopleHR",
  BambooHR = "BambooHR",
  WorkDay = "WorkDay",
  ADP = "ADP",
  ChartHop = "ChartHop",
  PayloCity = "Paylocity",
  BreatheHR = "BreatheHR",
  Kronos = "Kronos",
  UKG = "UKG",
  Sage = "SageHR",
  Rippling = "Rippling",
  HiBob = "HiBob",
}

interface HRCredentialsHeaders {
  usCustomerApiKey?: string
}

interface HRCredentials {
  username: string
  password: string
  headers?: HRCredentialsHeaders
  clientId?: string
  clientSecret?: string
  hostName: string
}

export interface HR {
  hrSystem: HRSystem
  apiKey: string
  signatureModuleId?: string
  subDomain: string
  extraModuleId?: string
  id: string
  slug?: string
  companyId?: string
  companyIds?: string[]
  credentials: HRCredentials
  ripplingConfig: {
    connected: boolean
  }
  isSSOEnabled: boolean
  isPatch: boolean
  isEnabled: boolean
  location: string
}

export interface HR_Row extends Integration_Row {
  data?: HR
}

export const hrPlatForms: HR_Row[] = [
  {
    name: HRSystem.PeopleHR,
    icon: peopleHr,
    // widthIcon: "120px",
    title: "PeopleHR",
    isSupported: true,
    maxWidth: 115,
    howToLink:
      "http://support.airmason.com/en/articles/4606527-how-to-connect-peoplehr-integrated-system",
  },
  {
    name: HRSystem.BambooHR,
    icon: bamboo,
    title: "BambooHR",
    isSupported: true,
    howToLink:
      "http://support.airmason.com/en/articles/4606878-how-to-connect-bamboohr-integrated-system",
  },
  {
    name: HRSystem.PayloCity,
    icon: paylocity,
    title: "Paylocity",
    maxWidth: 100,
    isSupported: true,
    howToLink:
      "http://support.airmason.com/en/articles/4707870-how-to-connect-paylocity-integrated-system",
  },
  {
    name: HRSystem.Kronos,
    icon: kronosIcon,
    title: "Kronos",
    maxWidth: 100,
    isSupported: true,
  },
  {
    name: HRSystem.WorkDay,
    icon: workday,
    title: "WorkDay",
    isSupported: true,
    maxWidth: 100,
    howToLink: "",
  },
  {
    name: HRSystem.ADP,
    icon: adp,
    title: "ADP",
    maxWidth: 100,
    isSupported: false,
  },
  {
    name: HRSystem.BreatheHR,
    icon: breatheHr,
    title: "BreatheHR",
    isSupported: true,
    maxWidth: 100,
    howToLink: "",
  },
  {
    name: HRSystem.UKG,
    icon: ukgIcon,
    title: "UKG",
    isSupported: true,
    maxWidth: 70,
    howToLink: "",
  },
  {
    name: HRSystem.Sage,
    icon: sageIcon,
    title: "Sage",
    isSupported: true,
    maxWidth: 95,
    howToLink: "",
  },
  {
    name: HRSystem.Rippling,
    icon: ripplingIcon,
    title: "Rippling",
    isSupported: true,
    maxWidth: 140,
    howToLink: "",
  },
  {
    name: HRSystem.HiBob,
    icon: HiBobIcon,
    title: "HiBob",
    isSupported: true,
    maxWidth: 55,
    howToLink: "",
  },
]