import React, { useState } from "react"
import { RouteComponentProps } from "@reach/router"
import Header from "./Header"
import About from "./About"
import IntegrationChecklist from "./Checklist/Integrations"

const PoliciesFinder: React.FC<RouteComponentProps<any>> = () => {
  const [succeeded, setSucceeded] = useState(false);
  return (
    <div className="flex flex-col bg-white mx-auto font-sans">
      <Header bgColor="bg-gray-2" />
      <About
        title={succeeded ? "All done!" : "Technical Integration Questionnaire"}
        description={succeeded
          ? ["Our team will be in touch soon."]
          : ["It's our aim to make sure you get the most out of the AirMason platform.",
            "Please have your IT team complete this short questionnaire",
            "so that we're able to connect regarding technical integrations."
          ]}
        showBackButton={succeeded}
      />
      {!succeeded && <IntegrationChecklist setSucceeded={setSucceeded} />}
    </div>
  )
}

export default PoliciesFinder
