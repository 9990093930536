import auth0 from "../../../images/integration/auth0.png"
import Gsuite from "../../../images/integration/g_suite.png"
import Okta from "../../../images/integration/okta.png"
import Onelog from "../../../images/integration/onelog.png"
import Azure from "../../../images/integration/azureactive.png"
import MS_Adfs from "../../../images/integration/ms_adfs.png"
import Idaptive from "../../../images/integration/idaptive.png"
import idento from "../../../images/integration/idento.png"
import jumpcloud from "../../../images/integration/jumpcloud.png"
import duoLogo from "../../../images/integration/duo.png"
import lassPass from "../../../images/integration/lastpass.png"
import MS_Admt from "../../../images/integration/ms_admt.jpg"
import optimalIcon from "../../../images/integration/optimal_idm_icon.png"
import cyberArkIcon from "../../../images/integration/cyberark.png"

export enum SSO_Provider {
  Onelogin = "Onelogin",
  Okta = "Okta",
  GSuite = "GSuite",
  Auth0 = "Auth0",
  MS_ADFS = "MS_ADFS", // Mircosoft - Active Directory Federation Services
  GG_SSO = "GG_SSO",
  Idaptive = "Idaptive",
  Idento = "Idento",
  JumpCloud = "JumpCloud",
  Duo = "Duo",
  LastPass = "LastPass",
  MS_ADMT = "MS_ADMT", // Microsoft - Azure Active Directory - Multi-tenant
  Optimal = "Optimal",
  CyberArk = "CyberArk",
}

export enum SSO_Provider_Type {
  MS_ADFS = "MS_ADFS",
  MS_Azure_AD = "MS_Azure_AD",
}

export interface SSOProviderEntity {
  id: number
  cert: string
  entryPoint: string
  issuer: string
  provider: SSO_Provider
  type?: SSO_Provider_Type
  entryPointWithQuery: string
  isEnabled: boolean
}

export interface Integration_Row {
  title?: string
  name: string
  icon: string
  isSupported: boolean
  maxWidth?: any
  className?: string
  connected?: boolean
  howToLink?: string
}

export interface SSO_Row extends Integration_Row {
  data?: SSOProviderEntity
  type?: SSO_Provider_Type
}

export const singleSigns: SSO_Row[] = [
  {
    name: SSO_Provider.Onelogin,
    icon: Onelog,
    isSupported: true,
    howToLink:
      "http://support.airmason.com/en/articles/4587665-how-to-connect-onelogin-sso",
  },
  {
    name: SSO_Provider.GG_SSO,
    title: "Google Suite",
    icon: Gsuite,
    isSupported: true,
    howToLink:
      "http://support.airmason.com/en/articles/4583366-how-to-connect-g-suite-sso",
  },
  {
    name: SSO_Provider.Okta,
    icon: Okta,
    isSupported: true,
    howToLink:
      "http://support.airmason.com/en/articles/4586770-how-to-connect-okta-sso",
  },
  {
    name: SSO_Provider.MS_ADFS,
    title: "Azure AD Connect",
    icon: Azure,
    isSupported: true,
    maxWidth: 105,
    className: "py-3",
    type: SSO_Provider_Type.MS_Azure_AD,
    howToLink:
      "http://support.airmason.com/en/articles/4587203-how-to-connect-microsoft-azure-sso",
  },
  {
    name: SSO_Provider.Idaptive,
    icon: Idaptive,
    isSupported: true,
    maxWidth: 100,
    howToLink:
      "http://support.airmason.com/en/articles/4587654-how-to-connect-idaptive-sso",
  },
  {
    name: SSO_Provider.MS_ADFS,
    title: "Microsoft ADFS",
    icon: MS_Adfs,
    isSupported: true,
    maxWidth: 140,
    type: SSO_Provider_Type.MS_ADFS,
    howToLink:
      "http://support.airmason.com/en/articles/4588678-how-to-connect-microsoft-adfs-sso",
  },
  {
    name: SSO_Provider.Idento,
    title: "Idento",
    icon: idento,
    isSupported: true,
    maxWidth: 105,
    howToLink: "",
  },
  {
    name: SSO_Provider.JumpCloud,
    title: "JumpCloud",
    icon: jumpcloud,
    isSupported: true,
    maxWidth: 105,
    howToLink: "",
  },
  {
    name: SSO_Provider.Auth0,
    icon: auth0,
    isSupported: false,
  },
  {
    name: SSO_Provider.Duo,
    title: "Duo",
    icon: duoLogo,
    isSupported: true,
    maxWidth: 80,
    howToLink:
      "http://support.airmason.com/en/articles/5291974-how-to-connect-duo-sso",
  },
  {
    name: SSO_Provider.LastPass,
    title: "LastPass",
    icon: lassPass,
    isSupported: true,
    maxWidth: 105,
    howToLink: "",
  },
  {
    name: SSO_Provider.MS_ADMT,
    title: "Azure AD For Multi-tenant",
    icon: MS_Admt,
    isSupported: true,
    maxWidth: 100,
    howToLink: "",
  },
  {
    name: SSO_Provider.Optimal,
    title: "Optimal IDM",
    icon: optimalIcon,
    isSupported: true,
    maxWidth: 150,
    howToLink: "",
  },
  {
    name: SSO_Provider.CyberArk,
    title: "CyberArk Identity",
    icon: cyberArkIcon,
    isSupported: true,
    maxWidth: 150,
    howToLink: "",
  },
]
